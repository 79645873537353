/* Font setup (importing Quicksand from Google Fonts if needed) */
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@400;600&display=swap');

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 40px;
  background-color: #ffffff;
  font-family: 'Quicksand', sans-serif;
}

.header-logo h1 {
  color: #F8D307;
  margin: 0;
  font-size: 1.8rem;
  font-weight: 600;
}

.header-slogan {
  color: #333;
  font-size: 0.8rem;
  margin: 0;
}

.header-nav a {
  margin: 0 15px;
  text-decoration: none;
  color: #333;
  font-weight: 600;
}

.header-nav a:hover {
  color: #F8D307;
}

.header-controls {
  display: flex;
  align-items: center;
}

.download-button {
  background-color: #F8D307;
  color: #ffffff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-weight: 600;
  cursor: pointer;
}

.download-button:hover {
  background-color: #ffd700;
}

.language-switch {
  margin-left: 15px;
  font-size: 0.9rem;
  color: #333;
  cursor: pointer;
}
