.home {
  text-align: center;
  padding: 60px 20px;
  font-family: 'Quicksand', sans-serif;
}

.home-title {
  color: #F8D307;
  font-size: 2.4rem;
  margin-bottom: 10px;
}

.home-description {
  color: #333;
  font-size: 1.1rem;
  margin-bottom: 30px;
}

.home-download-button {
  background-color: #F8D307;
  color: #ffffff;
  padding: 12px 24px;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
}

.home-download-button:hover {
  background-color: #ffd700;
}
